
import OrderCart from "./order-cart-v1"
import { CF2Component } from 'javascript/lander/runtime'

export default class OrderSummaryV1 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}

mount() {
    const loader = this.element.querySelector('.elSpinnerWrapper')
    this.summaryRowModel = document.querySelector('.elOrderSummary tbody tr')?.cloneNode(true)
    this.element.querySelectorAll('tbody tr').forEach(function (el) {
      el.remove()
    })
    this.setTotal('')

    document.addEventListener('OrderSummaryLegacyRender', (evt) => {
      this.renderSummary()
    })

    document.addEventListener('OrderSummaryStarted', (details) => {
      loader.dataset.loader = true
    })

    document.addEventListener('OrderSummaryFinished', (details) => {
      if (!details.detail || typeof details.detail != 'object' || details.detail.name != 'AbortError') {
        delete loader.dataset.loader
      }
    })

    this.renderSummary()
  }

  addProductVariant(row, productVariant) {
    row.setAttribute('data-product-variant-id', productVariant.id)
    row.querySelector('td.elOrderSummaryItem').innerHTML = productVariant.name
    row.querySelector('td.elOrderSummaryQuantity').innerHTML = String(productVariant.quantity)
    const productVariantsPrice = productVariant.priceCents / 100.0
    // row.querySelector('td.elOrderSummaryPrice').innerHTML = productVariant.selected_price.name
  }

  removeProductVariant(row) {
    row.remove()
  }

  setTotal(text) {
    this.element.querySelectorAll('tfoot .elOrderSummaryPrice').forEach(function (el) {
      el.textContent = text
    })
  }

  formatPrice(price, currency) {
    return [price < 0 ? '-' : '', currency, Math.abs(price).toFixed(2)].filter(Boolean).join('')
  }

  renderSummary() {
    const self = this
    const useCheckoutPreview = this.element.querySelector('.elOrderSummaryTaxTotal')
    if (useCheckoutPreview) {
      this.renderSummaryWithCheckoutPreview()
    }

    this.element.querySelectorAll('tr[data-product-variant-id]').forEach((row) => {
      const productId = row.getAttribute('data-product-variant-id')
      const product = OrderCart.productVariants.find((product) => product.id == productId)

      if (product) {
        this.addProductVariant(row, product)
      } else {
        this.removeProductVariant(row)
      }
    })

    OrderCart.productVariants.forEach(function (productVariant) {
      const id = 'tr[data-product-variant-id="' + productVariant.id + '"]'
      const elementSummaryRow = self.element.querySelector(id)
      if (!elementSummaryRow) {
        const clonedRow = self.summaryRowModel.cloneNode(true)
        self.addProductVariant(clonedRow, productVariant)
        self.element.querySelector('table tbody').appendChild(clonedRow)
      }
    })
  }

  displayIfValue(value, selector) {
    if (value && value > 0) {
      this.element.querySelector(selector).classList.remove('hide')
    } else {
      this.element.querySelector(selector).classList.add('hide')
    }
  }

  setSummaryPrices(checkoutPreview, currency) {
    this.element.querySelector('tfoot .elOrderSummaryTotal .elOrderSummaryPrice').textContent = this.formatPrice(
      checkoutPreview.total,
      currency
    )
    this.element.querySelector('tfoot .elOrderSummarySubTotal .elOrderSummaryPrice').textContent = this.formatPrice(
      checkoutPreview.subtotalAmount,
      currency
    )
    this.element.querySelector('tfoot .elOrderSummaryTaxTotal .elOrderSummaryPrice').textContent = this.formatPrice(
      checkoutPreview.taxAmount,
      currency
    )
    this.element.querySelector(
      'tfoot .elOrderSummaryShippingTotal .elOrderSummaryPrice'
    ).textContent = this.formatPrice(checkoutPreview.shippingAmount, currency)
    
    this.displayIfValue(checkoutPreview.discountsAmount, 'tfoot .elOrderSummaryDiscountTotal')
    this.element.querySelector(
      'tfoot .elOrderSummaryDiscountTotal .elOrderSummaryPrice'
    ).textContent = this.formatPrice(checkoutPreview.discountsAmount * -1, currency)
  }

  renderSummaryWithCheckoutPreview() {
    const lineItems = OrderCart.productVariants.map(({ id, quantity, priceId }) => ({ quantity, variant_id: id, price_id: priceId }))
    const couponCodes = [OrderCart.couponCode].filter(Boolean)
    const billingAddress = { street: '', street2: '', city: '', postalCode: '', region: '', country: '' }
    const deliveryAddress = { street: '', street2: '', city: '', postalCode: '', region: '', country: '' }
    const fetchUrl = window.location.origin + window.location.pathname + '/order_preview'

    if (this.requestFetcher) {
      this.requestFetcher.abort()
    }
    this.requestFetcher = null
    this.requestFetcher = new globalThis.CFFetcher()
    this.requestFetcher.fetch(fetchUrl,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          order: {
            line_items: lineItems,
            coupon_codes: couponCodes,
            billing_address: billingAddress,
            delivery_address: deliveryAddress,
          },
        }),
      },
      {
        customEvent: 'OrderSummary',
      }
    )
    .then((res) => { return res.json(); })
    .then((json) => {
      if (!json) return
      if (json.error == 'string') throw Error(json.error)
      this.setSummaryPrices(json, json.currencySymbol ?? '')
      const data = {orderPreviewData: json, productVariants: OrderCart.productVariants}
      window.dispatchEvent(new CustomEvent('payments:update-rebilly-config', {
        detail: {
          orderSummary: data
        }
      }))

      this.requestFetcher = null
    })
    .catch((error) => {
      console.error(error)
    })
  }



}

window["OrderSummaryV1"] = OrderSummaryV1

