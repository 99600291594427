
import OrderCart from "./order-cart-v1"
import "./modal-v1"
import "./input-v1"
import "./checkbox-v1"
import "./select-box-v2"
import { CF2Component } from 'javascript/lander/runtime'

export default class AddressV1 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}

REQUIRED_FIELDS = { 
    US: ['country', 'state', 'city', 'zip', 'address'],
    general: ['country', 'address', 'city']
  }
  ADDRESS_FIELDS = ['address', 'address_2', 'country', 'city', 'state', 'zip']
  PRETTY_NAMING = {
    shipping: 'Shipping',
    billing: 'Billing'
  }

  mount() {
    this.setup()
  }

  renderAndMount() {
    this.render(true)
    this.mount()
    if (this.newAddressFormEnabled) this.setFormDetails()
  }

  prepareInitialAddresses() {
    if (!this.checkout_state == 'saved') return
    if (this.type == 'shipping') {
      this.addresses = this.shipping_addresses
      if (!this.addresses.length) {
        this.userShouldConfirmAddress = true
        if (this.billing_addresses.length) {
          this.addresses = this.billing_addresses
        }
      }
    } else {
      this.addresses = this.billing_addresses
      if (this.shipping_addresses.length) {
        this.userShouldConfirmAddress = true
        if (this.shipping_addresses.length) {
          this.addresses = this.shipping_addresses
        }
      }
    }

    if (this.addresses.length && this.addresses[0].isCustom) {
      this.newAddressFormEnabled = true
    }
  }

  setup () {
    this.setAutocomplete()
    this.garlicValues = globalThis.CFGarlicValues ?? {}
    this.billing_same_as_shipping_checkbox = this.element.parentElement.querySelector('[name="billing_same_as_shipping"]')
    this.prepareInitialAddresses()

    if (!globalThis.ClickFunnels.all_countries) {
      window.addEventListener('CF2_COUNTRIES_FETCHED', () => {
        this.populateCountries()
      })
    } else {
      this.populateCountries()
    }
    if (this.showBillingSameAsShipping) {
      const shippingSameAsBilling = this.element.querySelector('.elShippingSameAsBilling input')
      shippingSameAsBilling?.addEventListener('change', (evt) => {
        if (evt.currentTarget.checked) {
          this.formEnabled = false
          this.clearFormFields();
        } else {
          this.formEnabled = true
        }
        this.updateOrderSummaryDetails(true, false)
        this.renderAndMount()
      })
    }

    // NOTE: This is simple a way to prevent invalid Google API keys affecting address form
    // https://developers.google.com/maps/documentation/javascript/events#auth-errors
    if (!window.gm_authFailureSet) {
      window.gm_authFailure = () => {
        const inputTargets = document.querySelectorAll('.pac-target-input')
        inputTargets.forEach((input, index) => {
          
          input.disabled = false
          input.placeholder = 'Address'
          input.style.backgroundImage = ''
          if (window.prevCheckoutAddressFocus == input) {
            input.focus()
          }
        })
      }
      window.gm_authFailureSet = true
    }

    const modal = this.getComponent("Modal/V1")
    if (modal) {
      const select = this.element.querySelector(`.elSuperSelect[name="${this.type}_id"]`)
      this.validValue = select.value
      const superSelectClickCb = (select) => {
        const value = select.value
        this.superSelectIndex = select.selectedIndex
        if (value.length == 0) {
          this.newAddressFormEnabled = true
          this.showModal = true
        } else {
          this.newAddressFormEnabled = false
          this.showModal = false
          this.validValue = value
        }
        this.renderAndMount()
      }

      if (this.addresses.length > 0 && this.addresses.every((addr) => addr.isCustom)) {
        select.addEventListener('click', (e) => {
          superSelectClickCb(e.target)
        })
      } else {
        select.addEventListener('change', (e) => {
          superSelectClickCb(e.target)
        })
      }


      modal.onClose = () => {
        select.value = this.validValue
        this.showModal = false
        if (this.superSelectIndex < this.addresses.length && 
          this.addresses[this.superSelectIndex].isCustom) {
          this.newAddressFormEnabled = true
        } else {
          this.newAddressFormEnabled = false
        }
        this.renderAndMount()
      }

      modal.element.querySelector('.elAddressAddNew').addEventListener('click', () => {
        if(this._checkFormFilled()) {
          this.validateAddress().then((res) => {
            if (res == 'wait') {
              setTimeout(() => {
                this.selectNewAddress()
              }, 3000)
            } else if (res == 'ok') {
              this.selectNewAddress()
            }
          })
        }
      })
    }

    ;['address', 'address_2', 'city', 'state', 'zip', 'country'].forEach((prop) => {
      const input = this.element.querySelector(`[name='${this.type}_${prop}']`)
      if(input !== null) {
         if (prop === 'state' || prop === 'country') {
          input.addEventListener('input', () => {
            if (prop === 'country') this.refreshStates()
            this.updateOrderSummaryDetails(true)
          })
        } else  {
          input.addEventListener('blur', () => {
            this.updateOrderSummaryDetails(true)
          })
        }
        input.addEventListener('focus', () => {
          window.CheckoutV1ClearSubmitErrors();
        })
      }
    })
  }

  updateOrderSummaryDetails(shouldFetchOrderPreview, checkIfFilledBeforeDispatch = true) {
    if ((checkIfFilledBeforeDispatch && !this.filled(false)) || !this.enabled) return
    OrderCart.details = OrderCart.details ?? {}
    OrderCart.details[this.type] = this.getFormDetails()
    OrderCart.details['billing_same_as_shipping'] = this.billing_same_as_shipping_checkbox?.checked ?? false
    OrderCart.dispatchOrderSummaryUpdate(shouldFetchOrderPreview)
  }

  selectNewAddress() {
    const select = this.element.querySelector(`.elSuperSelect[name="${this.type}_id"]`)
    const address = this.getFormDetails()
    const indexCustomAddress = this.addresses.findIndex((address) => address.isCustom)
    address.isCustom = true
    address.id = ''
    if (indexCustomAddress >= 0) {
      this.addresses[indexCustomAddress] = address
    } else {
      this.addresses.push(address)
      address.addressPrefix = '(New) '
    }
    this.validValue = ''
    this.showModal = false
    this.newAddressFormEnabled = true
    this.renderAndMount()
  }
  
  refreshStates() {
    const countriesSelect = this.element.querySelector(`[name='${this.type}_country']`)
    const statesSelect = this.element.querySelector(`[name="${this.type}_state"]`)
    if (!countriesSelect || !statesSelect) return
    let html = ''
    const countryData = globalThis.ClickFunnels.all_countries.find((country) => countriesSelect.value == country.iso2)
    countryData.regions.forEach((region, index) => {
      html += `<option data-google-name="${region.google_name}" value="${region.state_code}" > ${region.name} </option>`
    })
    $(statesSelect).parents('.elFormItemWrapper').addClass('hasValue')
    statesSelect.innerHTML = html
  }

  populateFieldWithPersistedValue(fieldType) {
    const field = this.element.querySelector(`[name='${this.type}_${fieldType}']`)
    const value = this.garlicValues[`${this.type}_${fieldType}`]
    if (value && field) {
      field.value = value
    }
  }

  populateCountryAndStateWithPersistedValues() {
    this.populateFieldWithPersistedValue('country')
    this.refreshStates()
    this.populateFieldWithPersistedValue('state')
    this.updateOrderSummaryDetails(true)
  }

  populateCountries() {
    const countriesSelect = this.element.querySelector(`[name='${this.type}_country']`)
    if (!countriesSelect) return

    countriesSelect.innerHTML = ''
    globalThis.ClickFunnels.populateSelect(countriesSelect, 'all_countries')
    $(countriesSelect).parents('.elFormItemWrapper').addClass('hasValue')
    this.refreshStates()
    this.populateCountryAndStateWithPersistedValues()
  }

  setAutocomplete() {
    if (!window.google?.maps?.places) return
    const addressInput = this.element.querySelector(`[name='${this.type}_address']`)
    if (!addressInput) return

    const autoCompleteValue = addressInput.getAttribute('autocomplete')
    const observer = new MutationObserver(function (mutationsList, observer) {
      for (const mutation of mutationsList) {
        if (mutation.attributeName == 'autocomplete' && mutation.target.getAttribute('autocomplete') == 'off') {
          observer.disconnect();
          if (mutation.target.value?.length) {
            mutation.target.setAttribute('autocomplete', 'none');
          } else {
            mutation.target.setAttribute('autocomplete', autoCompleteValue);
          }
        }
      }
    })
    observer.observe(addressInput, { attributes: true });

    addressInput.addEventListener('input', (e) => {
      if (e.target.value?.length) {
        e.target.setAttribute('autocomplete', 'none');
      } else {
        e.target.setAttribute('autocomplete', autoCompleteValue);
      }
    })

     addressInput.addEventListener('blur', (e) => {
      window.prevCheckoutAddressFocus = addressInput
      setTimeout(() => {
        if ($(addressInput).hasClass('gm-err-autocomplete')) {
         if (window.google.maps.event) {
           window.google.maps.event.removeListener(autoCompleteLsr)
           window.google.maps.event.clearInstanceListeners(addressInput)
         }
       }
      })
    })

    const autocompleteValue = addressInput.getAttribute('autocomplete')
    const autoComplete = new window.google.maps.places.Autocomplete(addressInput, {
      fields: ["address_components", "name"],
      types: ["address"],
    });
    addressInput.setAttribute('autocomplete', autocompleteValue)
    const autoCompleteLsr = autoComplete.addListener("place_changed", () => {
      const cityInput = this.element.querySelector(`[name="${this.type}_city"]`)
      const statesSelect = this.element.querySelector(`[name="${this.type}_state"]`)
      const countriesSelect = this.element.querySelector(`[name="${this.type}_country"]`)
      const zipInput = this.element.querySelector(`[name="${this.type}_zip"]`)
      const address2 = this.element.querySelector(`[name="${this.type}_address_2"]`)
      const place = autoComplete.getPlace();
      addressInput.value = place.name
      $(addressInput).parents('.elFormItemWrapper').addClass('hasValue')
      let hasLocality = false
      place.address_components.forEach((component) => {
        if (component.types.includes('locality')) {
          // City
          hasLocality = true
          cityInput.value = component.long_name
          $(cityInput).parents('.elFormItemWrapper').addClass('hasValue')
        } else if (component.types.includes('administrative_area_level_2') && !hasLocality) {
          // City
          cityInput.value = component.long_name
          $(cityInput).parents('.elFormItemWrapper').addClass('hasValue')
        } else if (component.types.includes('administrative_area_level_1')) {
          // State
          // This should run after country setup
          setTimeout(() => { 
            if (statesSelect.querySelector('[value="' + component.short_name + '"]')) {
              statesSelect.value = component.short_name
              $(statesSelect).parents('.elFormItemWrapper').addClass('hasValue')
            } else if (statesSelect.querySelector(`[data-google-name="${component.short_name}"]`)) {
              const dataGoogleOption = statesSelect.querySelector(`option[data-google-name="${component.short_name}"]`)
              statesSelect.value = dataGoogleOption.value
              $(statesSelect).parents('.elFormItemWrapper').addClass('hasValue')
            }
          }, 0)
        } else if (component.types.includes('country')) {
          // Country
          countriesSelect.value = component.short_name
          $(countriesSelect).parents('.elFormItemWrapper').addClass('hasValue')
          this.refreshStates()
        } else if (component.types.includes('postal_code')) {
          // Zip
          zipInput.value = component.long_name
          $(zipInput).parents('.elFormItemWrapper').addClass('hasValue')
        }
      })
      address2.focus()
    });
  }

  addError(error, errorType) {   
    const errorContainer = this.element.querySelector(`[data-address-error-type="${this.type}"]`)
    const messageElement = errorContainer?.querySelector(`[data-input-status-type="${errorType}"]`)
    if (messageElement) {
      messageElement.innerHTML += error
      messageElement.innerHTML += '<br/>'
    } else {
      console.warn(`Error type "${errorType}"  not identified for error: ${error}`)
    }
  }

  cleanErrors() {
    const errorContainer = this.element.querySelector(`[data-address-error-type="${this.type}"]`)
    errorContainer?.querySelectorAll('[data-input-status-type]').forEach((addr) => {
      addr.innerHTML = ''
    })
    this.element?.querySelectorAll('.elFormItemWrapper [data-input-status-type]').forEach((addr) => {
      addr.innerHTML = ''
    })
    
  }

  getFormDetails() {
    return ['address', 'address_2', 'city', 'state', 'zip', 'country'].reduce((acc, prop) => {
      acc[prop] = this.element.querySelector(`[name='${this.type}_${prop}']`)?.value
      return acc
    }, {})
  }

  setFormDetails() {
    const address = this.addresses[this.superSelectIndex ?? 0]
    if (this.type == 'billing' && this.showBillingSameAsShipping) return
    if (!address || !address.isCustom) return
    const setFormDetailsCb = () => {
      this.ADDRESS_FIELDS.forEach((prop) => {
        const input = this.element.querySelector(`[name='${this.type}_${prop}']`)
        input.value = address[prop] ?? ''
        if (prop == 'country') {
          this.refreshStates()
        }
      })
    }
    if (globalThis.ClickFunnels.all_countries) {
      setFormDetailsCb()
    } else {
      window.addEventListener('CF2_COUNTRIES_FETCHED', () => {
        setFormDetailsCb()
      })
    }
  }

  filled(addErrors = true) {
    const superSelectValue = !!this.element.querySelector(`.elSuperSelect[name="${this.type}_id"]`)?.value?.length
    return !!superSelectValue || this._checkFormFilled(addErrors)
  }

  validateAddress() {
    const $formItemsWrapper = $(".elFormItemWrapper:not(.elSuperSelectWrapper)", this.element)
    $formItemsWrapper.addClass("elInputValid")
    $formItemsWrapper.removeClass("elInputError")
    $formItemsWrapper.removeClass("elInputWarning")
    return Promise.resolve('ok')
  }

  hasSavedAddress() {
    return this.checkout_state == 'saved' && ((this.addresses?.length ?? 0) > 0) && this.formEnabled
  }

  _checkFormFilled(addErrors = true) {
    if (!this._isFilled(addErrors)) {
      return false
    }
    return true
  }

  _isFilled(addErrors = true) {
    const address = this.getFormDetails()
    const country = address.country
    const requiredFields = this.REQUIRED_FIELDS[country] ?? this.REQUIRED_FIELDS.general
    const fieldValues = requiredFields.map((field) => {
      if (!address[field]?.length) {
        const element = this.element.querySelector(`[name='${this.type}_${field}']`)
        const $parents = $(element).parents('.elFormItemWrapper')
        const $warning = $parents.find('[data-input-status-type]')
        if (addErrors) {
          $parents.addClass("elInputError")
          $warning.attr('data-input-status-type', 'error')
          $warning.text('Required field')
        }
        return false
      }
      return true
    })
    return fieldValues.every((r) => !!r)
  }

  clearFormFields() {
    this.ADDRESS_FIELDS.forEach((prop) => {
      const input = this.element.querySelector(`[name='${this.type}_${prop}']`)
      if (input) input.value = ''
    });
    globalThis.processForm();
  }


    remove() {
      this.element.innerHTML = '';
    }
    render(initializeChildrenInstances = false) {
      const is_server = true;
      const type = this.type ?? null ;
const useCheckboxIcon = this.useCheckboxIcon ?? null ;
const checkout_state = this.checkout_state ?? null ;
const addressIcon = this.addressIcon ?? null ;
const shipping_addresses = this.shipping_addresses ?? null ;
const billing_addresses = this.billing_addresses ?? null ;
const showBillingSameAsShipping = this.showBillingSameAsShipping ?? null ;
const always_show_shipping_address = this.always_show_shipping_address ?? null ;
const formEnabled = this.formEnabled ?? null ;
const labelType = this.labelType ?? null ;
const addresses = this.addresses ?? [] ;
const newAddressFormEnabled = this.newAddressFormEnabled ?? false ;
const showModal = this.showModal ?? false ;
const enabled = this.enabled ?? true ;
const superSelectIndex = this.superSelectIndex ?? 0 ;

      
      let html = '';
      {
        let modalEnabled = false;
        let currentAddresses = addresses ?? "";
        if (checkout_state == "saved" && formEnabled) {
          if (currentAddresses.length == 0) {
            if (type == "shipping") {
              currentAddresses = shipping_addresses;
            } else {
              currentAddresses = billing_addresses;
            }
          }
          modalEnabled = currentAddresses.length > 0;
        }
        if (showBillingSameAsShipping) {
          html += `<div class="elShippingSameAsBillingContainer"><label class="elShippingSameAsBilling">`
          if (formEnabled == false) {
            html += `<div data-page-element="Checkbox/V1" type="" class="elCheckboxWrapper elFormItemWrapper de-input-block id-Checkbox/V1" data-liquid-replace="item" data-prevent-click-on-label="false" data-trigger-click-on-wrapper="false" data-checked="true"><label class="elCheckboxLabel"><input type="checkbox" value="true" name="billing_same_as_shipping" checked data-prevent-garlic="true" class="elCheckboxInput inputHolder elFormItem elInput"/><div class="elCheckbox"><div class="elCheckboxIcon"><div class="elCheckboxIconSquare`
            if (useCheckboxIcon) {
              html += ` hide`
            }
            html += `"></div><i class="elCheckboxIconCheck fas fa-check`
            if (useCheckboxIcon == false) {
              html += ` hide`
            }
            html += `"></i></div></div><span class="elCheckboxText">Use billing address same as shipping</span></label><div data-input-status-type="true" data-error-container="true"></div></div>`
          } else {
            html += `<div data-page-element="Checkbox/V1" type="" class="elCheckboxWrapper elFormItemWrapper de-input-block id-Checkbox/V1" data-liquid-replace="item" data-prevent-click-on-label="false" data-trigger-click-on-wrapper="false" data-checked=""><label class="elCheckboxLabel"><input type="checkbox" value="true" name="billing_same_as_shipping"  data-prevent-garlic="true" class="elCheckboxInput inputHolder elFormItem elInput"/><div class="elCheckbox"><div class="elCheckboxIcon"><div class="elCheckboxIconSquare`
            if (useCheckboxIcon) {
              html += ` hide`
            }
            html += `"></div><i class="elCheckboxIconCheck fas fa-check`
            if (useCheckboxIcon == false) {
              html += ` hide`
            }
            html += `"></i></div></div><span class="elCheckboxText">Use billing address same as shipping</span></label><div data-input-status-type="true" data-error-container="true"></div></div>`
          }
          html += `</label></div>`
        }
        if (modalEnabled) {
          html += `<span><div data-page-element="Modal/V1" class="elModal elModal id-Modal/V1`
          if (showModal) {
            html += ` elShowModal`
          }
          html += `"><div class="elModalInnerContainer"><div class="elModalClose"><i data-page-element="CloseIcon/V1" class="fas fa-times elClose id-CloseIcon/V1"></i></div><div class="elModalInner">`
          if (newAddressFormEnabled) {
            html += `<div data-page-element="RowContainer/V1" class="row id-RowContainer/V1" data-slot-name="default"><div data-page-element="ColContainer/V1" class="col-md-12 innerContent col_left  id-ColContainer/V1"><div class="col-inner"><div data-page-element="SubHeadline/V1" class="elSubheadlineWrapper elCheckoutFormLabel id-SubHeadline/V1"><h2 class="elSubheadline"><span>${type} details</span></h2></div></div></div></div>`
            html += `<div data-page-element="FlexContainer/V1" class="row id-FlexContainer/V1" data-slot-name="default"><div data-page-element="ColContainer/V1" class="col-md-8 innerContent col_left colAddress id-ColContainer/V1"><div class="col-inner">`
            const address_name = type.toString().concat("_address");
            const address_autocomplete = type.toString().concat(" address-line1");
            html += `<div data-page-element="Input/V1" class="elFormItemWrapper elInputWrapper de-input-block elInputOuterTopLeftCorner id-Input/V1`
            if (labelType == "inside") {
              html += ` labelInside`
            }
            if (labelType == "on-border") {
              html += ` labelOnBorder`
            }
            html += `" type="${address_name}" data-liquid-replace="item">`
            if (address_name == "phone_number") {
            }
            html += `<input class="elFormItem elInput inputHolder " value="" type="text" name="${address_name}" pattern="^[\\w\\s\\-\\/\\p{L},.#;:()'&]+$" autocomplete="${address_autocomplete}" inputtype="" data-prevent-submit="" data-prevent-garlic="" placeholder="Address" maxlength="60"/>`
            if (labelType != "none") {
              html += `<div class="elLabel"><div class="borderHolder"></div><div class="borderHolder labelText"><label>Address</label></div><div class="borderHolder"></div></div>`
            }
            html += `<div class="inputHolder" data-input-status-type="true" data-error-container="true"></div><div class="input-icon"><i class="fas fa-exclamation"></i></div></div></div></div><div data-page-element="ColContainer/V1" class="col-md-4 innerContent col_left colAddress2 id-ColContainer/V1"><div class="col-inner">`
            const address2_name = type.toString().concat("_address_2");
            html += `<div data-page-element="Input/V1" class="elFormItemWrapper elInputWrapper de-input-block elInputOuterTopRightCorner id-Input/V1`
            if (labelType == "inside") {
              html += ` labelInside`
            }
            if (labelType == "on-border") {
              html += ` labelOnBorder`
            }
            html += `" type="${address2_name}" data-liquid-replace="item">`
            if (address2_name == "phone_number") {
            }
            html += `<input class="elFormItem elInput inputHolder " value="" type="text" name="${address2_name}" pattern="^[\\w\\s\\-\\/\\p{L},.#;:()'&]+$" autocomplete="" inputtype="" data-prevent-submit="" data-prevent-garlic="" placeholder="Apartment, building, floor" maxlength="60"/>`
            if (labelType != "none") {
              html += `<div class="elLabel"><div class="borderHolder"></div><div class="borderHolder labelText"><label>Apartment, building, floor</label></div><div class="borderHolder"></div></div>`
            }
            html += `<div class="inputHolder" data-input-status-type="true" data-error-container="true"></div><div class="input-icon"><i class="fas fa-exclamation"></i></div></div></div></div></div>`
            html += `<div data-page-element="FlexContainer/V1" class="row id-FlexContainer/V1" data-slot-name="default"><div data-page-element="ColContainer/V1" class="col-md-8 innerContent col_left colCity id-ColContainer/V1"><div class="col-inner">`
            const city_name = type.toString().concat("_city");
            const city_autocomplete = type.toString().concat(" address-level2");
            html += `<div data-page-element="Input/V1" class="elFormItemWrapper elInputWrapper de-input-block id-Input/V1`
            if (labelType == "inside") {
              html += ` labelInside`
            }
            if (labelType == "on-border") {
              html += ` labelOnBorder`
            }
            html += `" type="${city_name}" data-liquid-replace="item">`
            if (city_name == "phone_number") {
            }
            html += `<input class="elFormItem elInput inputHolder " value="" type="text" name="${city_name}" pattern="^[\\w\\s\\-\\p{L},.']+$" autocomplete="${city_autocomplete}" inputtype="" data-prevent-submit="" data-prevent-garlic="" placeholder="City" maxlength="45"/>`
            if (labelType != "none") {
              html += `<div class="elLabel"><div class="borderHolder"></div><div class="borderHolder labelText"><label>City</label></div><div class="borderHolder"></div></div>`
            }
            html += `<div class="inputHolder" data-input-status-type="true" data-error-container="true"></div><div class="input-icon"><i class="fas fa-exclamation"></i></div></div></div></div><div data-page-element="ColContainer/V1" class="col-md-4 innerContent col_left colZip id-ColContainer/V1"><div class="col-inner">`
            const zip_name = type.toString().concat("_zip");
            const zip_autocomplete = type.toString().concat(" postal-code");
            html += `<div data-page-element="Input/V1" class="elFormItemWrapper elInputWrapper de-input-block id-Input/V1`
            if (labelType == "inside") {
              html += ` labelInside`
            }
            if (labelType == "on-border") {
              html += ` labelOnBorder`
            }
            html += `" type="${zip_name}" data-liquid-replace="item">`
            if (zip_name == "phone_number") {
            }
            html += `<input class="elFormItem elInput inputHolder " value="" type="text" name="${zip_name}" pattern="^[\\w\\s\\-]+$" autocomplete="${zip_autocomplete}" inputtype="" data-prevent-submit="" data-prevent-garlic="" placeholder="Postal Code" maxlength="10"/>`
            if (labelType != "none") {
              html += `<div class="elLabel"><div class="borderHolder"></div><div class="borderHolder labelText"><label>Postal Code</label></div><div class="borderHolder"></div></div>`
            }
            html += `<div class="inputHolder" data-input-status-type="true" data-error-container="true"></div><div class="input-icon"><i class="fas fa-exclamation"></i></div></div></div></div></div>`
            html += `<div data-page-element="FlexContainer/V1" class="row id-FlexContainer/V1" data-slot-name="default"><div data-page-element="ColContainer/V1" class="col-md-6 innerContent col_left colCountry id-ColContainer/V1"><div class="col-inner">`
            const country_name = type.toString().concat("_country");
            const country_autocomplete = type.toString().concat(" country");
            html += `<div data-page-element="SelectBox/V2" class="elFormItemWrapper elSelectWrapper de-input-block elInputOuterBottomLeftCorner id-SelectBox/V2`
            if ("Select Country" && labelType == "inside") {
              html += ` labelInside`
            }
            if ("Select Country" && labelType == "on-border") {
              html += ` labelOnBorder`
            }
            html += `" type="${country_name}" data-liquid-replace="item"><select class="elFormItem elSelect inputHolder " name="${country_name}" autocomplete="${country_autocomplete}" data-prevent-submit="" data-prevent-garlic="false"><option>Select Country</option></select><div class="elSelectArrow"><i class="fas fa-chevron-down"></i></div><div class="elLabel"><div class="borderHolder"></div><div class="borderHolder labelText"><label>Select Country</label></div><div class="borderHolder"></div></div><div class="inputHolder" data-input-status-type="true" data-error-container="true"></div></div></div></div><div data-page-element="ColContainer/V1" class="col-md-6 innerContent col_left colState id-ColContainer/V1"><div class="col-inner">`
            const state_name = type.toString().concat("_state");
            const state_autocomplete = type.toString().concat(" address-level1");
            html += `<div data-page-element="SelectBox/V2" class="elFormItemWrapper elSelectWrapper de-input-block elInputOuterBottomRightCorner id-SelectBox/V2`
            if ("Select State" && labelType == "inside") {
              html += ` labelInside`
            }
            if ("Select State" && labelType == "on-border") {
              html += ` labelOnBorder`
            }
            html += `" type="${state_name}" data-liquid-replace="item"><select class="elFormItem elSelect inputHolder " name="${state_name}" autocomplete="${state_autocomplete}" data-prevent-submit="" data-prevent-garlic="false"><option label="Select State"></option></select><div class="elSelectArrow"><i class="fas fa-chevron-down"></i></div><div class="elLabel"><div class="borderHolder"></div><div class="borderHolder labelText"><label>Select State</label></div><div class="borderHolder"></div></div><div class="inputHolder" data-input-status-type="true" data-error-container="true"></div></div></div></div></div>`
            html += `<div class="inputStatusContainer" data-address-error-type="${type}"><div data-input-status-type="error"></div><div data-input-status-type="warning"></div></div>`
          }
          html += `<div data-page-element="RowContainer/V1" class="row id-RowContainer/V1" data-slot-name="default"><div data-page-element="ColContainer/V1" class="col-md-12 innerContent col_left  id-ColContainer/V1"><div class="col-inner"><div class="elDefaultAddress"></div><div data-page-element="Button/V1" class="elBTN elAddressAddNew id-Button/V1" data-liquid-replace="item"><a target="_self" href="#" class="elButton" aria-label="" data-disabled="false" data-show-button-ids="" data-hide-button-ids="" data-on-submit-go-to="" data-param-submittingText="Submitting..." data-style-guide-button="" rel="noopener"><span class="elButtonMain"><i class="fas fa-spinner fa-spin elButtonSpinner elButtonText"></i><span class="elButtonMainText elButtonText">Update</span></span><span class="elButtonSub"></span></a></div></div></div></div></div></div></div></span>`
          html += `<div class="elAddressForm">`
          const super_select_name = type.toString().concat("_id");
          html += `<div data-page-element="SuperSelectBox/V1" class="elFormItemWrapper elSuperSelectWrapper de-input-block  id-SuperSelectBox/V1" type="${super_select_name}"><div data-liquid-replace="item"><label class="elSuperSelectLabel elSelectWrapper"><span class="elSuperSelectIcon"><i class="${addressIcon}"></i></span><span class="elSuperSelectLabelText">${type} address</span><select class="elFormItem elSuperSelect" name="${super_select_name}" data-prevent-submit="">`
          const c0 = currentAddresses
          const fl1 = new CF2ForloopDrop(c0.length)
          for (const address of c0) {
            const forloop = fl1
            const currentIndex = forloop.index0;
            if (currentIndex == superSelectIndex) {
              html += `<option selected="selected" value="${address.id ?? ""}" data-custom-address="${address.isCustom ?? "false"}">${address.addressPrefix ?? ""}${address.address}. ${address.city}, ${address.state} - ${address.zip}. ${address.country}</option>`
            } else {
              html += `<option value="${address.id ?? ""}" data-custom-address="${address.isCustom ?? "false"}">${address.addressPrefix ?? ""}${address.address}. ${address.city}, ${address.state} - ${address.zip}. ${address.country}</option>`
            }
            forloop.next();
          }
          const customAddresses = currentAddresses.filter((value) => value["isCustom"] == true);
          if (customAddresses[0] == null) {
            html += `<option data-custom-address="true" label="Custom Address "></option>`
          }
          html += `</select><div class="elSelectArrow"><i class="fas fa-chevron-down"></i></div></label></div></div></div>`
        } else {
          html += `<div class="elAddressForm elCheckoutFormContactContainer">`
          if (formEnabled == true) {
            html += `<div data-page-element="RowContainer/V1" class="row id-RowContainer/V1" data-slot-name="default"><div data-page-element="ColContainer/V1" class="col-md-12 innerContent col_left  id-ColContainer/V1"><div class="col-inner"><div data-page-element="SubHeadline/V1" class="elSubheadlineWrapper elCheckoutFormLabel id-SubHeadline/V1"><h2 class="elSubheadline"><span>${type} details</span></h2></div></div></div></div>`
            html += `<div data-page-element="FlexContainer/V1" class="row id-FlexContainer/V1" data-slot-name="default"><div data-page-element="ColContainer/V1" class="col-md-8 innerContent col_left colAddress id-ColContainer/V1"><div class="col-inner">`
            const address_name = type.toString().concat("_address");
            const address_autocomplete = type.toString().concat(" address-line1");
            html += `<div data-page-element="Input/V1" class="elFormItemWrapper elInputWrapper de-input-block elInputOuterTopLeftCorner id-Input/V1`
            if (labelType == "inside") {
              html += ` labelInside`
            }
            if (labelType == "on-border") {
              html += ` labelOnBorder`
            }
            html += `" type="${address_name}" data-liquid-replace="item">`
            if (address_name == "phone_number") {
            }
            html += `<input class="elFormItem elInput inputHolder " value="" type="text" name="${address_name}" pattern="^[\\w\\s\\-\\/\\p{L},.#;:()'&]+$" autocomplete="${address_autocomplete}" inputtype="" data-prevent-submit="" data-prevent-garlic="" placeholder="Address" maxlength="60"/>`
            if (labelType != "none") {
              html += `<div class="elLabel"><div class="borderHolder"></div><div class="borderHolder labelText"><label>Address</label></div><div class="borderHolder"></div></div>`
            }
            html += `<div class="inputHolder" data-input-status-type="true" data-error-container="true"></div><div class="input-icon"><i class="fas fa-exclamation"></i></div></div></div></div><div data-page-element="ColContainer/V1" class="col-md-4 innerContent col_left colAddress2 id-ColContainer/V1"><div class="col-inner">`
            const address2_name = type.toString().concat("_address_2");
            html += `<div data-page-element="Input/V1" class="elFormItemWrapper elInputWrapper de-input-block elInputOuterTopRightCorner id-Input/V1`
            if (labelType == "inside") {
              html += ` labelInside`
            }
            if (labelType == "on-border") {
              html += ` labelOnBorder`
            }
            html += `" type="${address2_name}" data-liquid-replace="item">`
            if (address2_name == "phone_number") {
            }
            html += `<input class="elFormItem elInput inputHolder " value="" type="text" name="${address2_name}" pattern="^[\\w\\s\\-\\/\\p{L},.#;:()'&]+$" autocomplete="" inputtype="" data-prevent-submit="" data-prevent-garlic="" placeholder="Apartment, building, floor" maxlength="60"/>`
            if (labelType != "none") {
              html += `<div class="elLabel"><div class="borderHolder"></div><div class="borderHolder labelText"><label>Apartment, building, floor</label></div><div class="borderHolder"></div></div>`
            }
            html += `<div class="inputHolder" data-input-status-type="true" data-error-container="true"></div><div class="input-icon"><i class="fas fa-exclamation"></i></div></div></div></div></div>`
            html += `<div data-page-element="FlexContainer/V1" class="row id-FlexContainer/V1" data-slot-name="default"><div data-page-element="ColContainer/V1" class="col-md-8 innerContent col_left colCity id-ColContainer/V1"><div class="col-inner">`
            const city_name = type.toString().concat("_city");
            const city_autocomplete = type.toString().concat(" address-level2");
            html += `<div data-page-element="Input/V1" class="elFormItemWrapper elInputWrapper de-input-block id-Input/V1`
            if (labelType == "inside") {
              html += ` labelInside`
            }
            if (labelType == "on-border") {
              html += ` labelOnBorder`
            }
            html += `" type="${city_name}" data-liquid-replace="item">`
            if (city_name == "phone_number") {
            }
            html += `<input class="elFormItem elInput inputHolder " value="" type="text" name="${city_name}" pattern="^[\\w\\s\\-\\p{L},.']+$" autocomplete="${city_autocomplete}" inputtype="" data-prevent-submit="" data-prevent-garlic="" placeholder="City" maxlength="45"/>`
            if (labelType != "none") {
              html += `<div class="elLabel"><div class="borderHolder"></div><div class="borderHolder labelText"><label>City</label></div><div class="borderHolder"></div></div>`
            }
            html += `<div class="inputHolder" data-input-status-type="true" data-error-container="true"></div><div class="input-icon"><i class="fas fa-exclamation"></i></div></div></div></div><div data-page-element="ColContainer/V1" class="col-md-4 innerContent col_left colZip id-ColContainer/V1"><div class="col-inner">`
            const zip_name = type.toString().concat("_zip");
            const zip_autocomplete = type.toString().concat(" postal-code");
            html += `<div data-page-element="Input/V1" class="elFormItemWrapper elInputWrapper de-input-block id-Input/V1`
            if (labelType == "inside") {
              html += ` labelInside`
            }
            if (labelType == "on-border") {
              html += ` labelOnBorder`
            }
            html += `" type="${zip_name}" data-liquid-replace="item">`
            if (zip_name == "phone_number") {
            }
            html += `<input class="elFormItem elInput inputHolder " value="" type="text" name="${zip_name}" pattern="^[\\w\\s\\-]+$" autocomplete="${zip_autocomplete}" inputtype="" data-prevent-submit="" data-prevent-garlic="" placeholder="Postal Code" maxlength="10"/>`
            if (labelType != "none") {
              html += `<div class="elLabel"><div class="borderHolder"></div><div class="borderHolder labelText"><label>Postal Code</label></div><div class="borderHolder"></div></div>`
            }
            html += `<div class="inputHolder" data-input-status-type="true" data-error-container="true"></div><div class="input-icon"><i class="fas fa-exclamation"></i></div></div></div></div></div>`
            html += `<div data-page-element="FlexContainer/V1" class="row id-FlexContainer/V1" data-slot-name="default"><div data-page-element="ColContainer/V1" class="col-md-6 innerContent col_left colCountry id-ColContainer/V1"><div class="col-inner">`
            const country_name = type.toString().concat("_country");
            const country_autocomplete = type.toString().concat(" country");
            html += `<div data-page-element="SelectBox/V2" class="elFormItemWrapper elSelectWrapper de-input-block elInputOuterBottomLeftCorner id-SelectBox/V2`
            if ("Select Country" && labelType == "inside") {
              html += ` labelInside`
            }
            if ("Select Country" && labelType == "on-border") {
              html += ` labelOnBorder`
            }
            html += `" type="${country_name}" data-liquid-replace="item"><select class="elFormItem elSelect inputHolder " name="${country_name}" autocomplete="${country_autocomplete}" data-prevent-submit="" data-prevent-garlic="false"><option>Select Country</option></select><div class="elSelectArrow"><i class="fas fa-chevron-down"></i></div><div class="elLabel"><div class="borderHolder"></div><div class="borderHolder labelText"><label>Select Country</label></div><div class="borderHolder"></div></div><div class="inputHolder" data-input-status-type="true" data-error-container="true"></div></div></div></div><div data-page-element="ColContainer/V1" class="col-md-6 innerContent col_left colState id-ColContainer/V1"><div class="col-inner">`
            const state_name = type.toString().concat("_state");
            const state_autocomplete = type.toString().concat(" address-level1");
            html += `<div data-page-element="SelectBox/V2" class="elFormItemWrapper elSelectWrapper de-input-block elInputOuterBottomRightCorner id-SelectBox/V2`
            if ("Select State" && labelType == "inside") {
              html += ` labelInside`
            }
            if ("Select State" && labelType == "on-border") {
              html += ` labelOnBorder`
            }
            html += `" type="${state_name}" data-liquid-replace="item"><select class="elFormItem elSelect inputHolder " name="${state_name}" autocomplete="${state_autocomplete}" data-prevent-submit="" data-prevent-garlic="false"><option label="Select State"></option></select><div class="elSelectArrow"><i class="fas fa-chevron-down"></i></div><div class="elLabel"><div class="borderHolder"></div><div class="borderHolder labelText"><label>Select State</label></div><div class="borderHolder"></div></div><div class="inputHolder" data-input-status-type="true" data-error-container="true"></div></div></div></div></div>`
            html += `<div class="inputStatusContainer" data-address-error-type="${type}"><div data-input-status-type="error"></div><div data-input-status-type="warning"></div></div>`
          }
          html += `</div>`
        }

      }

      this.element.innerHTML = html;

      if (initializeChildrenInstances) {
        CF2Component.hydrateTree(this.element);
      }
    }


}

window["AddressV1"] = AddressV1

