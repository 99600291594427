
          
          
          class OrderCartV1 {
    static getInstance() {
      // This is a singleton
      if (this._instance) {
        return this._instance
      }
      this._instance = new OrderCartV1();
      this._instance.productVariants = []
      return this._instance
    }

    unescape(str) {
      const unescapeMap = {
        '&amp;': '&',
        '&lt;': '<',
        '&gt;': '>',
        '&#34;': '"',
        '&#39;': "'",
      }
      return String(str).replace(/&(amp|lt|gt|#34|#39);/g, (m) => unescapeMap[m])
    }

    dispatchOrderSummaryUpdate(shouldFetchOrderPreview = true) {
      if (this.throttleTimer) return
      this.throttleTimer = true
      setTimeout(() => {
        document.dispatchEvent(new CustomEvent('OrderSummaryLegacyRender'))
        document.dispatchEvent(new CustomEvent('OrderSummaryRenderBefore'))
        document.dispatchEvent(
          new CustomEvent('OrderSummaryRender', {
            detail: {
              options: {
                shouldFetchOrderPreview: shouldFetchOrderPreview,
              },
            },
          })
        )
        this.throttleTimer = false
      }, 100)
    }

    updateProductVariant(newProductVariant) {
      let cartUpdated
      this.productVariants = this.productVariants.map((product) => {
        if (newProductVariant.id == product.id) {
          cartUpdated = true
          return newProductVariant
        }
        return product
      })
      if (!cartUpdated) {
        this.productVariants.push(newProductVariant)
      }
      this.cleanUp()
      this.dispatchOrderSummaryUpdate()
    }

    removeProductVariant(id) {
      this.productVariants = this.productVariants.filter((variant) => id !== variant.id)
      this.dispatchOrderSummaryUpdate()
    }

    removeAllProductVariants() {
      this.productVariants = []
      this.dispatchOrderSummaryUpdate()
    }

    getProductVariant(input, type = 'single') {
      let quantity
      const id = input.getAttribute('data-product-variant-id')
      const name = input.getAttribute('data-product-variant-name')
      const image = input.getAttribute('data-product-variant-image')
      const productType = input.getAttribute('data-product-variant-product-type')
      const currency = input.getAttribute('data-product-variant-price-currency')
      const priceId = Number(input.getAttribute('data-product-variant-price-id'))
      const priceCents = Number(input.getAttribute('data-product-variant-price-cents'))
      const prices = JSON.parse(this.unescape(input.getAttribute('data-product-variant-prices')) ?? '[]')

      if (input.type == 'checkbox') {
        quantity = Number(input.checked)
      } else if (type == 'single') {
        quantity = 1
      } else {
        quantity = Number(input.value)
      }
      return {
        id,
        name,
        quantity,
        currency,
        image,
        priceCents,
        priceId,
        productType,
        prices,
      }
    }

    updateProductVariantQuantity(id, quantity) {
      this.productVariants = this.productVariants.map((p) => (p.id === id ? { ...p, quantity: quantity } : p))
      this.cleanUp()
      this.dispatchOrderSummaryUpdate()
    }

    incrementProductVariantQuantity(product, increment) {
      this.productVariants = this.productVariants.map((p) =>
        p.id === product.id ? { ...p, quantity: p.quantity + increment } : p
      )
      this.cleanUp()
      this.dispatchOrderSummaryUpdate()
    }

    updateCouponCode(couponCode) {
      this.couponCode = couponCode
      this.dispatchOrderSummaryUpdate()
    }

    totalCents() {
      return this.productVariants.reduce(function (acc, product) {
        return acc + product.quantity * product.priceCents
      }, 0)
    }

    clear() {
      this.productVariants = []
    }

    cleanUp() {
      this.productVariants = this.productVariants.filter((p) => p.quantity > 0)
    }
  }
  const orderCart = OrderCartV1.getInstance()
  // TODO: Remove this and import the module in submit.ts
  // The problem is that all pages will include OrderCart, is that desirable?
  window["OrderCart/V1"] = { default: orderCart }
  export default orderCart
        