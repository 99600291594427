
import OrderCart from "./order-cart-v1"
import "./product-item-v1"
import { CF2Component } from 'javascript/lander/runtime'

export default class ProductListV1 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}

mount() {
    this.productItems = this.getComponents("ProductItem/V1");
    if(this.productItems.length > 0 && this.productItems[0].selectStyle === "single") {
      this.productItems.forEach((productItem) => {
        productItem.on("updateSelectedVariant", () => {
          this.productItems.forEach((otherProductItem) => {
            if(otherProductItem != productItem) {
              otherProductItem.unselectVariant()
            }
          })
        });
      })
    }
  }



}

window["ProductListV1"] = ProductListV1

